<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <a :target="targetBlank" :href="buttonUrl" class="article-item2">
    <v-row no-gutters class="flex-column-reverse flex-lg-row">
      <v-col
        cols="12"
        lg="6"
        class="d-flex flex-column justify-center align-center"
      >
        <div class="article-item2-box">
          <h3 class="article-item2-box__title text-overflow-ep-2">
            {{ title }}
          </h3>
          <div
            v-html="content"
            class="article-item2-box__content text-overflow-ep-6"
          ></div>
          <Main-btn
            checkLink
            :to="buttonUrl"
            linkOut
            :targetBlank="targetBlank"
            >{{ buttonText }}</Main-btn
          >
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <v-img
          cover
          width="100%"
          :min-height="minHeight"
          :height="height"
          :aspect-ratio="ratio"
          class="article-item2-img"
          :src="photoUrl"
          :alt="photoName"
        >
        </v-img>
      </v-col>
    </v-row>
  </a>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import owlItemMixins from "@/components/owl/owlItem.js";
export default {
  mixins: [owlItemMixins],
  computed: {
    windowWidth() {
      return this.$store.getters["base/windowWidth"];
    },
    height() {
      if (this.windowWidth > this.$vuetify.breakpoint.thresholds.md) return '100%';
      return 'auto';
    },
    minHeight() {
      if (this.windowWidth > this.$vuetify.breakpoint.thresholds.md) return 420;
      return 0;
    },
    ratio() {
      return 3 / 2;
    },
  },
};
</script>